import React, { useEffect, useState } from 'react'
import OrangeButton from '../../components/OrangeButton'

import { ReactComponent as TwitterNew } from '../../assets/twitter_new.svg'
import { ReactComponent as TelegramNew } from '../../assets/telegram_new.svg'
import RenderLinksWithIcon from '../../components/SocialButtons'
import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useFetchClaimMerkleProofQuery } from '../../services/modules/project'
import {
  useAccount,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi'
import { AvailableContracts } from '../../hooks/useContract/types'

import ClaimAbi from '../../contracts/claim.json'
import ClaimNewAbi from '../../contracts/claim_new.json'

import { ethers } from 'ethers'
import { useFetchSingleParticipationQuery } from '../../services/modules/participations'
import { useAppDispatch } from '../../hooks'
import { setSnackbar } from '../../redux/slices/themeSlice'
import { StyledBox } from './IDODetails'
import InfoBox from './InfoBox'
import {
  ClaimMetrics1,
  ClaimMetrics2,
  ClaimMetrics3,
  ClaimMetrics4,
  GreenFilledArrow,
  GreenTick,
} from '../../assets'

import ClaimSuccessModal from '../../components/ClaimSuccessModal'
import GreenButton from '../../components/GreenButton'
import moment from 'moment'

const GeneralClaim = ({ idoData, projectId }: any) => {
  console.log('GeneralClaim', idoData)
  const theme = useTheme()
  const isDown768 = useMediaQuery(theme.breakpoints.down(768))

  const [isLoading, setIsLoading] = useState(false)

  const {
    data: merkleProofData,
    isLoading: isMerkleProofLoading,
    isSuccess: isMerkleProofSuccess,
    error: merkleProofError,
    isError: isMerkleProofError,
  } = useFetchClaimMerkleProofQuery({
    projectId: idoData.project._id,
    idoNumber: 0,
  })
  const { address } = useAccount()
  const [openClaimSuccessModal, setOpenClaimSuccessModal] = useState(false)

  const [claimed, setClaimed] = useState(false)

  const dispatch = useAppDispatch()

  const { data: foundParticipation, isLoading: singleLoading } =
    useFetchSingleParticipationQuery({
      projectId,
      idoNumber: 0,
      address: address || '',
    })

  console.log('foundParticipation', foundParticipation)

  console.log('merkleProofData', merkleProofData, merkleProofError)
  const {
    data: canClaimedData,
    isSuccess: isClaimedSuccess,
    error: isClaimedError,
    isError: isClaimedIsError,
  } = useReadContract({
    address: idoData?.claimContract,
    abi: ClaimNewAbi,
    functionName: 'canClaim',
    args: [address],
  })

  console.log(
    'canClaimedData',
    idoData,
    idoData?.claimContract,
    canClaimedData,
    isClaimedError,
  )

  const {
    data: getClaimableAmountData,
    isSuccess: isGetClaimableAmountSuccess,
    error: getClaimableAmountError,
    isError: getClaimableAmountIsError,
  } = useReadContract({
    address: idoData?.claimContract,
    abi: ClaimNewAbi,
    functionName: 'getClaimableAmount',
    args: [address],
  })
  const {
    data: tgeData,
    isSuccess: isTgeSuccess,
    error: isTgeError,
    isError: isTgeIsError,
  } = useReadContract({
    address: idoData?.claimContract,
    abi: ClaimNewAbi,
    functionName: 'tge',
  })
  const {
    data: durationData,
    isSuccess: isDurationSuccess,
    error: isDurationError,
    isError: isDurationIsError,
  } = useReadContract({
    address: idoData?.claimContract,
    abi: ClaimNewAbi,
    functionName: 'duration',
  })
  const {
    data: cliffData,
    isSuccess: isCliffSuccess,
    error: isCliffError,
    isError: isCliffIsError,
  } = useReadContract({
    address: idoData?.claimContract,
    abi: ClaimNewAbi,
    functionName: 'cliff',
  })

  console.log(
    'getClaimableAmount',
    getClaimableAmountData,
    getClaimableAmountError,
    Number(tgeData),
    Number(durationData),
    Number(cliffData),
  )

  // Step 1: Parse the TgeDate
  const tgeMoment = moment(Number(tgeData) * 1000)

  // Step 2: Add the Cliff period to TgeDate
  const cliffEndMoment = tgeMoment.clone().add(Number(cliffData), 'seconds')

  // Step 3: Add the duration period to the result from step 2
  const finalUnlockMoment = cliffEndMoment
    .clone()
    .add(Number(durationData), 'seconds')

  // Step 4: Calculate the difference between the current date and the final unlock date
  const currentMoment = moment()
  const daysLeft = finalUnlockMoment.diff(currentMoment, 'days')

  console.log(`Days left until all tokens are unlocked: ${daysLeft}`)

  const {
    data: tokenDecimalData,
    isSuccess: isTokenDecimalSuccess,
    error: tokenDecimalError,
    isError: tokenDecimalIsError,
  } = useReadContract({
    address: idoData?.claimContract,
    abi: ClaimNewAbi,
    functionName: 'returnTokenDecimal',
  })

  const {
    data: claimedAmountData,
    isSuccess: isClaimedAmountSuccess,
    error: claimedAmountError,
    isError: claimedAmountIsError,
  } = useReadContract({
    address: idoData?.claimContract,
    abi: ClaimNewAbi,
    functionName: 'claimedAmount',
    args: [address],
  })

  console.log('allClaimDetails', {
    canClaimedData,
    isClaimedError,
    getClaimableAmountData,
    getClaimableAmountError,
    tokenDecimalData,
    tokenDecimalError,
    claimedAmountData,
    claimedAmountError,
    parsedValue: getClaimableAmountData
      ? ethers.utils.parseUnits(getClaimableAmountData.toString(), 18)
      : 0,
  })

  let parsedValue =
    getClaimableAmountData && tokenDecimalData
      ? Number(
          ethers.utils.formatUnits(
            getClaimableAmountData.toString(),
            Number(tokenDecimalData),
          ),
        )
      : 0

  const {
    data: claimData,
    isError: isErrorClaim,
    isPending: isLoadingClaim,
    isSuccess: isSuccessClaim,
    writeContractAsync: claim,
  } = useWriteContract()

  const {
    data: claimReceipt,
    isError: isErrorClaimReceipt,
    isLoading: isLoadingClaimReceipt,
    isSuccess: isSuccessClaimReceipt,
  } = useWaitForTransactionReceipt({
    hash: claimData,

    confirmations: 1,
  })
  useEffect(() => {
    if (claimReceipt) {
      console.log('claimReceipt', claimReceipt)

      dispatch(
        setSnackbar({
          open: true,
          severity: 'success',
          message: 'Claimed Successfully!',
        }),
      )

      setClaimed(true)
      setIsLoading(false)
      //onClaimed()
    }
  }, [claimReceipt])

  const onClaim = async () => {
    try {
      setIsLoading(true)

      if (foundParticipation) {
        console.log('claim running')

        claim({
          address: idoData?.claimContract,
          abi: ClaimNewAbi,
          functionName: 'claim',
        })
      }
    } catch (error) {
      console.log('onClaim error', error)
      setIsLoading(false)
    }
  }

  let remainingTokens = 0

  // total tokens - claimedTokens
  if (idoData.totalTokens && Number(claimedAmountData)) {
    console.log('coming inside')
    remainingTokens =
      Number(idoData.totalTokens) -
      Number(
        Number(
          Number(
            ethers.utils.formatUnits(
              claimedAmountData!.toString(),
              Number(tokenDecimalData),
            ),
          )  +(idoData?.pastClaimedTokens || 0),
        ).toFixed(4),
      ) -
      parsedValue
  }else if (idoData.totalTokens && idoData?.pastClaimedTokens) {
    remainingTokens =
      Number(idoData.totalTokens) -
      Number(
        Number(
          (idoData?.pastClaimedTokens || 0),
        ).toFixed(4),
      ) -
      parsedValue
  }

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: 'Hellix',
          fontSize: '24px',
          fontWeight: 600,
          color: '#FFFFFF',
          margin: '0px',
          lineHeight: '31.2px',
          paddingBottom: '24px',
        }}
      >
        Claim Details
      </Typography>
      <StyledBox>
        <InfoBox
          imageSrc={ClaimMetrics1}
          headingText="Available for Claim"
          headingVal={`${
            !foundParticipation?.isInvestor || !canClaimedData
              ? 0
              : Number(parsedValue.toFixed(4)).toLocaleString('en')
          }`}
          spanText={idoData.claimRefund ? 'USDC' : idoData.project.token.symbol}
        />
        <InfoBox
          imageSrc={ClaimMetrics2}
          headingText="Total Tokens"
          headingVal={`${Number(idoData.totalTokens).toLocaleString('en')}`}
          spanText={idoData.claimRefund ? 'USDC' : idoData.project.token.symbol}
        />
      </StyledBox>
      <StyledBox>
        <InfoBox
          imageSrc={ClaimMetrics3}
          headingText="Claimed Tokens"
          headingVal={`${
            claimedAmountData && tokenDecimalData
              ? Number(
                  Number(
                    Number(
                      ethers.utils.formatUnits(
                        claimedAmountData.toString(),
                        Number(tokenDecimalData),
                      ),
                    ) + (idoData?.pastClaimedTokens || 0),
                  ).toFixed(4),
                ).toLocaleString('en')
              : !claimedAmountData && idoData?.pastClaimedTokens
              ? idoData?.pastClaimedTokens.toLocaleString('en')
              : 0
          }`}
          spanText={idoData.claimRefund ? 'USDC' : idoData.project.token.symbol}
        />
        <InfoBox
          imageSrc={ClaimMetrics4}
          headingText="Vesting Details"
          headingVal={
            daysLeft < 0 && remainingTokens === 0 ? 'All Claimed' : ``
          }
          hasGradient={daysLeft < 0 && remainingTokens === 0}
          multipleValues={
            daysLeft < 0 && remainingTokens === 0
              ? []
              : [
                  {
                    headingText: daysLeft > 0 ? 'Total Unlock in ' : '',
                    headingVal:
                      daysLeft > 0 ? `${daysLeft} days` : 'All Unlocked',
                  },
                  {
                    headingText: 'Remaining Tokens : ',
                    headingVal: `${Number(remainingTokens).toLocaleString(
                      'en',
                    )}`,
                  },
                ]
          }
        />
      </StyledBox>

      <GreenButton
        fs={15}
        lh="22.5px"
        fm="Inter"
        fw={400}
        br="24px"
        IconStart={() => (
          <img
            src={GreenFilledArrow}
            style={{ marginRight: '7px' }}
            alt="forward icon"
          />
        )}
        text={
          parsedValue < 0 ? 'Claim Locked' : isLoading ? 'Loading...' : 'Claim'
        }
        disabled={
          claimed ||
          parsedValue <= 0 ||
          isLoading ||
          !foundParticipation?.isInvestor
        }
        mt={3}
        onClick={() => {
          // setOpenClaimSuccessModal(true)
          onClaim()
        }}
        outlined={true}
        w="137px"
        h="48px"
        border={'1px solid #08493C'}
        sx={{
          background: '#000000 !important',
          border: '1px solid #08493C !important',
        }}
      />
      <ClaimSuccessModal
        open={openClaimSuccessModal}
        handleClose={() => setOpenClaimSuccessModal(false)}
        title="Claimed Successfully"
        subtext="Your tokens have been claimed successfully!"
        imageSrc={GreenTick}
      />
    </Box>
  )
}

export default GeneralClaim
