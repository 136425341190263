import React from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { useAnalytics } from '../../AmplitudeContext'

interface IParticipationTypeTabs {
  handleTabChange: (value: number) => void
  tab: number
}

const ParticipationTypeTabs: React.FC<IParticipationTypeTabs> = ({
  handleTabChange,
  tab,
}) => {
  const activeTabBorder = '0.4px solid rgba(255, 255, 255, 0.16)'
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const { trackEvent } = useAnalytics();

  return (
    <Grid
      container
      height={isDown1000 ? '36px' : '48px'}
      minHeight={isDown1000 ? '36px' : '48px'}
      borderRadius={'25px'}
      bgcolor={'#0F1113'}
      maxWidth={'100%'}
      width={'100%'}
      flex={1}
      className="participation-type-tabs"
    >
      <Grid
        item
        xs={4}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        border={tab === 0 ? activeTabBorder : 'none'}
        onClick={() => handleTabChange(0)}
        sx={{
          cursor: 'pointer',
          borderRadius: '25px',
          backgroundColor: tab === 0 ? 'rgba(111, 235, 124, 0.03)' : 'none',
          '&:hover .participation-type-tabs__text': {
            color: tab === 0 ? '#6FEB7C' : '#FFFFFF',
          },
        }}
      >
        <Typography
          fontSize={'15px'}
          fontWeight={400}
          lineHeight={'22.5px'}
          fontFamily={'Inter'}
          color={tab === 0 ? '#6FEB7C' : tab === 1 ? '#B1B4B3' : '#B1B4B3'}
          style={{ transition: 'color 0.3s' }}
          className="participation-type-tabs__text"
        >
          My Sales
        </Typography>
      </Grid>

      <Grid
        item
        xs={4}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        border={tab === 1 ? activeTabBorder : 'none'}
        onClick={() => {
          handleTabChange(1)
          trackEvent('PortfolioReferralsOpen', { referrer: document.referrer });
        }}
        sx={{
          cursor: 'pointer',
          borderRadius: '25px',
          backgroundColor: tab === 1 ? 'rgba(111, 235, 124, 0.03)' : 'none',
          '&:hover .participation-type-tabs__text': {
            color: tab === 1 ? '#6FEB7C' : '#FFFFFF',
          },
        }}
      >
        <Typography
          fontSize={'15px'}
          fontWeight={400}
          lineHeight={'22.5px'}
          fontFamily={'Inter'}
          color={tab === 1 ? '#6FEB7C' : tab === 0 ? '#B1B4B3' : '#B1B4B3'}
          style={{ transition: 'color 0.3s' }}
          className="participation-type-tabs__text"
        >
          My Referral
        </Typography>
      </Grid>

      <Grid
        item
        xs={4}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        border={tab === 2 ? activeTabBorder : 'none'}
        onClick={() => {
          handleTabChange(2)
          trackEvent('PortfolioClaimOpen');
        }}
        // onClick={() => {}}
        sx={{
          cursor: 'pointer',
          borderRadius: '25px',
          backgroundColor: tab === 2 ? 'rgba(111, 235, 124, 0.03)' : 'none',
          '&:hover .participation-type-tabs__text': {
            color: tab === 2 ? '#6FEB7C' : '#FFFFFF',
          },
        }}
      >
        <Typography
          fontSize={'15px'}
          fontWeight={400}
          lineHeight={'22.5px'}
          fontFamily={'Inter'}
          color={tab === 2 ? '#6FEB7C' : tab === 0 ? '#B1B4B3' : '#B1B4B3'}
          style={{ transition: 'color 0.3s' }}
          className="participation-type-tabs__text"
        >
          Claim
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ParticipationTypeTabs
