import React, { Suspense, lazy, useEffect, useRef, useState } from 'react'
import Navbar from '../../components//Navbar'

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

// import Launchpad from '../../pages/Launchpad'
import Footer from '../../components/Footer/index'

import Loader from '../../components//Loader'
import { useAppDispatch, useAppSelector } from '../../hooks/'
import { Alert, Box, Snackbar } from '@mui/material'
import {
  initialStateSnackbar,
  setSnackbar,
} from '../../redux/slices/themeSlice'
import {
  useRegisterUserEmailMutation,
  useUpdateHoldingMutation,
} from '../../services/modules/users'
import Lottie from 'lottie-react'
import LogoAnimation from '../../animations/logo loading white.json'
import Web3AuthModalButton from '../../components//Web3AuthModal/Web3AuthModal'
import ThemeContainer from './ThemeContainer'
import PrivacyPolicy from '../../pages/PrivacyPolicy'
import TermsOfServices from '../../pages/TermsOfServices'

import useDisconnectWalletAndProfile from '../../hooks/useDisconnectWalletAndEmail'
import { useAccount, useBalance, useDisconnect } from 'wagmi'
import NotFound from '../../components/NotFound'
import ChangeNetworkModal from '../../components/ChangeNetworkModal'
import RevampedLaunchpad from '../../pages/RevampedLaunchpad'
import RevampedOverview from '../../pages/RevampedOverview'
import RevampedParticipationFlow from '../../pages/RevampedParticipationFlow'
import RevampedPortfolio from '../../pages/RevampedPortfolio'
import RevampedTransparency from '../../pages/RevampedTransparency'
import FAQ from '../../pages/FAQ'
import GiftCardBanner from '../../components/GiftCardBanner'
import { AnalyticsProvider } from '../../AmplitudeContext'

// const RevampedLaunchpad = lazy(() => import('../../pages/RevampedLaunchpad'))
// const RevampedOverview = lazy(() => import('../../pages/RevampedOverview'))
// const RevampedParticipationFlow = lazy(
//   () => import('../../pages/RevampedParticipationFlow'),
// )
// const RevampedPortfolio = lazy(() => import('../../pages/RevampedPortfolio'))
// const RevampedTransparency = lazy(
//   () => import('../../pages/RevampedTransparency'),
// )
// const FAQ = lazy(() => import('../../pages/FAQ'))

// lazy load portfolio component
// const Portfolio = lazy(() => import('../../pages/Portfolio'))
// const Participate = lazy(() => import('../../pages/Participate'))
// const Launch = lazy(() => import('../../pages/Launch'))
// const Overview = lazy(() => import('../../pages/Overview'))

const Router = () => {
  const dispatch = useAppDispatch()

  const user = useAppSelector(state => state.user)
  const { logoLoading } = useAppSelector(state => state.theme)
  const snackbarSelector = useAppSelector(state => state.theme.snackbar)
  const txInProgress = useAppSelector(state => state.wallet.txInProgress)

  const [
    updateHoldings,
    {
      data: dataUpdateHoldings,
      isLoading: isLoadingUpdateHoldings,
      isSuccess: isSuccessUpdateHoldings,
      isError: isErrorUpdateHoldings,
      error: errorUpdateHoldings,
    },
  ] = useUpdateHoldingMutation()

  const { address, chain } = useAccount()

  const { disconnectWalletAndProfile } = useDisconnectWalletAndProfile()

  const mainAppToken = useAppSelector(state => state.user.mainAppToken)

  const [registerProfile, {}] = useRegisterUserEmailMutation()

  const { disconnect } = useDisconnect()

  const [pageLoading, setPageLoading] = useState(true)

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setSnackbar(initialStateSnackbar))
  }
  useEffect(() => {
    if (mainAppToken && address) updateHoldings(address)
  }, [mainAppToken, address])

  useEffect(() => {
    console.log('setting timer useEffect', pageLoading)
    if (pageLoading) {
      console.log('setting timer', pageLoading)
      setTimeout(() => {
        console.log('5 seconds passed', pageLoading)
        document?.body?.classList?.remove('no-scroll')

        setPageLoading(false)
      }, 500)
    }
  }, [pageLoading])

  //Web3AuthModalStore

  // if (logoLoading) {
  //   return (
  //     <Box
  //       sx={{
  //         backgroundColor: '#000',
  //         position: 'absolute',
  //         top: 0,
  //         left: 0,
  //         width: '100%',
  //         height: '100%',
  //         zIndex: 1000000,
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <Lottie
  //         animationData={LogoAnimation}
  //         loop={true}
  //         style={{
  //           height: '20vh',
  //           // width: '100vw',
  //         }}
  //       />
  //     </Box>
  //   )
  // }

  const [giftBannerHeight, setShowGiftBannerHeight] = useState('45px')

  const handleBannerDisplay = (data: any) => {
    setShowGiftBannerHeight(data ? '45px' : '0px')
  }
  return (
    <BrowserRouter>
      <AnalyticsProvider apiKey="73b7617d7e536d286e2c757693a70218">
        {/* {pageLoading && (
          <Box
            sx={{
              backgroundColor: '#000',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1000000,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Lottie
              animationData={LogoAnimation}
              loop={true}
              style={{
                height: '20vh',
                // width: '100vw',
              }}
            />
          </Box>
        )} */}
        {true && (
          // <Web3AuthModalButton
          //   roundedButton
          //   displayBanner={handleBannerDisplay}
          // />
          <GiftCardBanner onCloseClick={() => {}} onButtonClick={() => {}} />
        )}

        {txInProgress && <Loader />}

        {/* overlay loader */}
        {/* {!pageLoaded && (
          <Box
            sx={{
              backgroundColor: '#000',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1000000,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Lottie
              animationData={LogoAnimation}
              loop={true}
              style={{
                height: '20vh',
                // width: '100vw',
              }}
            />
          </Box>
        )} */}

        <Navbar isLoading={false} giftBannerHeight={giftBannerHeight} />

        <ThemeContainer container flexDirection={'column'}>
          <Box
            alignSelf={'center'}
            display={'flex'}
            justifyContent={'center'}
            width={'100%'}
          >
            <Routes>
              <Route path="/launchpad" element={<Navigate to="/" replace />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="terms-of-service" element={<TermsOfServices />} />
              {/* <Route path="/" element={<Launchpad />} /> */}
              <Route path="/" element={<RevampedLaunchpad />} />
              <Route
                path="/participate/ido"
                element={<RevampedParticipationFlow />}
              />
              <Route
                path="/project/:projectName"
                element={
                  <Suspense
                    fallback={
                      <Box
                        sx={{
                          backgroundColor: '#000',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          zIndex: 1000000,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Lottie
                          animationData={LogoAnimation}
                          loop={true}
                          style={{
                            height: '20vh',
                          }}
                        />
                      </Box>
                    }
                  >
                    <RevampedOverview />
                  </Suspense>
                }
              />

              {address && (
                <Route
                  path="/portfolio"
                  element={
                    <Suspense
                      fallback={
                        <Box
                          sx={{
                            backgroundColor: '#000',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1000000,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Lottie
                            animationData={LogoAnimation}
                            loop={true}
                            style={{
                              height: '20vh',
                              // width: '100vw',
                            }}
                          />
                        </Box>
                      }
                    >
                      <RevampedPortfolio />
                    </Suspense>
                  }
                />
              )}

              {/* <Route
                path="/project/:projectName"
                element={
                  <Suspense
                    fallback={
                      <Box
                        sx={{
                          backgroundColor: '#000',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          zIndex: 1000000,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Lottie
                          animationData={LogoAnimation}
                          loop={true}
                          style={{
                            height: '20vh',
                            // width: '100vw',
                          }}
                        />
                      </Box>
                    }
                  >
                    <Overview />
                  </Suspense>
                }
              /> */}
              <Route
                path="/participate/:projectName/:idoNumber"
                element={
                  // user.isLoggedIn ? (
                  //   <Participate />
                  // ) : (
                  //   <Navigate to="/" replace />
                  // )
                  <Suspense
                    fallback={
                      <Box
                        sx={{
                          backgroundColor: '#000',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          zIndex: 1000000,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Lottie
                          animationData={LogoAnimation}
                          loop={true}
                          style={{
                            height: '20vh',
                            // width: '100vw',
                          }}
                        />
                      </Box>
                    }
                  >
                    <RevampedParticipationFlow />
                  </Suspense>
                }
              />

              {/* <Route
                path="/launch/:projectName/:idoNumber"
                element={
                  user.isLoggedIn ? (
                    <Launch />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              /> */}

              {/* <Route path="/referral" element={<Referrals />} /> */}
              <Route
                path="/transparency"
                element={
                  <RevampedTransparency />
                  // <Transparency />
                }
              />
              {/* <Route
                path="/revamped-transparency"
                element={<RevampedTransparency />}
              /> */}

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </ThemeContainer>
        <Footer />
        <Snackbar
          open={snackbarSelector?.open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <Alert
            onClose={handleClose}
            severity={snackbarSelector?.severity}
            sx={{ width: '100%', background: '#000', color: '#FFFFFF' }}
          >
            {snackbarSelector?.message}
          </Alert>
        </Snackbar>
        {/* <ChangeNetworkModal
          open={(address && chain?.id !== 1) || false}
          setOpen={() => {}}
        /> */}
      </AnalyticsProvider>
    </BrowserRouter>
  )
}

export default Router
