import { Box, Typography, useMediaQuery } from '@mui/material'
import {
  PortfolioSocials,
  PortfolioInvestment,
  PortfolioMoney,
  PortfolioOrangeKYC,
  PortfolioGreenKYC,
  PortfolioAnnualized,
  NoData,
} from '../../assets'
import InfoBox from './InfoBox'
import KYCnSocialBox from './KYCnSocialBox'
import { useEffect, useMemo, useState } from 'react'
import ParticipationTypeTabs from './ParticipationTypeTabs'
import MySalesTable from './MySalesTable'
import MyReferralsTable from './MyReferralsTable'
import ClaimTable from './ClaimTable'
import NoDataTable from './NoDataTable'
import {
  IParticipation,
  useFetchUserAllParticipationsQuery,
  useFetchUserPortfolioStatsQuery,
  useLazyFetchUserAllParticipationsQuery,
} from '../../services/modules/participations'
import {
  sortIDOsOnPortfolioPageByTimestamp,
  sortItemsBySmallerTimestamp,
} from '../../utils'
import { useAppSelector } from '../../hooks'
import KYCButton from '../../components/KYCBox/kycButton'
import { useFetchUserReferralsQuery } from '../../services/modules/reffer'
import { useAccount } from 'wagmi'
import { IdoStatus } from '../../services/modules/project'
import NoDataBox from './NoDataBox'
import { useSearchParams } from 'react-router-dom'
import Lottie from 'lottie-react'
import LogoAnimation from '../../animations/logo loading white.json'
import { useAnalytics } from '../../AmplitudeContext'

const RevampedPortfolio = () => {
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown770 = useMediaQuery('(max-width:770px)')

  const [fetchUserAllParticipations, { data, isLoading, isSuccess }] =
    useLazyFetchUserAllParticipationsQuery()

  const { data: portfolioStats, refetch: fetchUserPortfolioStats } =
    useFetchUserPortfolioStatsQuery()

  console.log('useFetchUserPortfolioStatsQuery data', portfolioStats)

  console.log('useFetchUserAllParticipationsQuery data', data)

  const [participationType, setParticipationType] = useState(0)
  const handleParticipationTypeChange = (value: number) => {
    setParticipationType(value)
  }
  const mainAppToken = useAppSelector(state => state.user.mainAppToken)

  const { address } = useAccount()

  // get query params
  const [searchParams] = useSearchParams()

  const [showLoader, setShowLoader] = useState(false)

  const {
    data: userReferralData,
    isLoading: userReferralDataLoading,
    refetch: fetchUserReferrals,
  } = useFetchUserReferralsQuery(undefined, {
    skip: !address || !mainAppToken,
    refetchOnFocus: false,
  })

  console.log('mainAppToken', mainAppToken)

  useEffect(() => {
    if (showLoader) {
      // false after 4c seconds
      setTimeout(() => {
        setShowLoader(false)
      }, 4000)
    }
  }, [showLoader])

  useEffect(() => {
    if (mainAppToken) {
      setShowLoader(true)
      setTimeout(() => {
        fetchUserReferrals()
      }, 3000)
    }
  }, [mainAppToken, fetchUserReferrals])

  useEffect(() => {
    if (mainAppToken) {
      setTimeout(() => {
        fetchUserPortfolioStats()
      }, 3000)
    }
  }, [mainAppToken, fetchUserPortfolioStats])

  useEffect(() => {
    if (mainAppToken) {
      console.log('calling gain', mainAppToken)
      setTimeout(() => {
        fetchUserAllParticipations()
      }, 3000)
    }
    console.log('fetchUserAllParticipations', fetchUserAllParticipations)
  }, [mainAppToken, fetchUserAllParticipations])

  useEffect(() => {
    if (searchParams.get('referral') && userReferralData) {
      // setMainTab(2) after 1 second
      setTimeout(() => {
        setParticipationType(1)
      }, 100)
    }
  }, [searchParams, userReferralData])

  const loadingAllData = userReferralDataLoading

  const statusOrder: IdoStatus[] = [
    IdoStatus.PARTICIPATION,
    IdoStatus.INVESTMENT,
    IdoStatus.TGE,
    IdoStatus.COMPLETED,
  ]

  const sortedArray = useMemo(() => {
    if (userReferralData) {
      let sortedArray: any = []

      console.log('userReferralData', userReferralData)
      sortedArray = [...userReferralData].sort((a, b) => {
        const statusA = statusOrder.indexOf(a.ido.status)
        const statusB = statusOrder.indexOf(b.ido.status)

        // Sorting in ascending order based on the order defined in statusOrder
        return statusA - statusB
      })

      sortedArray = sortItemsBySmallerTimestamp(sortedArray)

      // keep participation items status at top and then other items
      sortedArray = [
        ...sortedArray.filter(
          (item: any) => item.ido.status === IdoStatus.PARTICIPATION,
        ),
        ...sortedArray
          .filter((item: any) => item.ido.status !== IdoStatus.PARTICIPATION)
          .reverse(),
      ]

      return sortedArray
    } else return []
  }, [userReferralData])

  console.log('sortedArray', sortedArray)

  const [mySales, setMySales] = useState<IParticipation[]>([])

  console.log('mySales', mySales)

  const user = useAppSelector(state => state.user)
  const KYC_STATUS = user.kycStatus
  const IS_KYC_STATUS_APPROVED = user.kycStatus === 'approved'

  const [isKYCVerified, setIsKYCVerified] = useState(false)
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (data) {
      setMySales(sortIDOsOnPortfolioPageByTimestamp([...data]))
    }
  }, [data])

  const scrollToParticipationTypeTabs = () => {
    const participationTypeTabs = document.querySelector(
      '.participation-type-tabs',
    )
    if (participationTypeTabs) {
      participationTypeTabs.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleReferralsClick = () => {
    trackEvent('PortfolioReferralsOpen', { referrer: document.referrer });
  };

  if (showLoader)
    return (
      <Box
        sx={{
          backgroundColor: '#000',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1000000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Lottie
          animationData={LogoAnimation}
          loop={true}
          style={{
            height: '20vh',
          }}
        />
      </Box>
    )

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'black',
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'center',
        paddingX: '20px',
      }}
    >
      <Box
        maxWidth={'996px'}
        width={'100%'}
        alignSelf={'center'}
        display={'flex'}
        flexDirection={'column'}
        paddingTop={'80px'}
      >
        <Box
          display={'flex'}
          flexDirection={isDown1000 ? 'column-reverse' : 'column'}
          gap={'8px'}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: isDown770 ? 'column' : 'row',
              gap: '8px',
            }}
          >
            <KYCnSocialBox
              image={
                IS_KYC_STATUS_APPROVED ? PortfolioGreenKYC : PortfolioOrangeKYC
              }
              heading="KYC Verification"
              subheading={
                IS_KYC_STATUS_APPROVED
                  ? 'Your identity has been confirmed.'
                  : 'Complete a simple KYC process.'
              }
              buttonText={IS_KYC_STATUS_APPROVED ? 'Approved' : 'Complete now'}
              buttonAction={() => {
                setIsKYCVerified(!isKYCVerified)
              }}
              KYCButton={IS_KYC_STATUS_APPROVED ? null : KYCButton}
            />
            <KYCnSocialBox
              image={PortfolioSocials}
              heading="Social Connections"
              subheading="Increase your chances of winning."
              buttonText="Referrals"
              buttonAction={() => {
                setParticipationType(1)
                scrollToParticipationTypeTabs()
                handleReferralsClick()
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                gap: '8px',
              }}
            >
              <InfoBox
                imageSrc={PortfolioMoney}
                headingText="Amount Invested"
                headingVal={
                  portfolioStats && portfolioStats?.amountInvested
                    ? `$${portfolioStats?.amountInvested.toLocaleString('en')}`
                    : '$0,000.00'
                }
                noBal={!portfolioStats?.amountInvested}
              />
              <InfoBox
                imageSrc={PortfolioInvestment}
                headingText="Investment Gain"
                headingVal={
                  portfolioStats && portfolioStats?.investmentGain
                    ? `$${portfolioStats?.investmentGain.toLocaleString('en')}`
                    : '$0,000.00'
                }
                noBal={!portfolioStats?.investmentGain}
              />
              {!isDown1000 && (
                <InfoBox
                  imageSrc={PortfolioAnnualized}
                  headingText="Annualized ROI"
                  headingVal={
                    portfolioStats && portfolioStats?.annualizedROI !== null
                      ? portfolioStats?.annualizedROI?.toFixed(2)
                      : '0.00'
                  }
                  noBal={!portfolioStats?.annualizedROI}
                />
              )}
            </Box>
            {isDown1000 && (
              <InfoBox
                imageSrc={PortfolioAnnualized}
                headingText="Annualized ROI"
                headingVal={
                  portfolioStats && portfolioStats?.annualizedROI !== null
                    ? portfolioStats?.annualizedROI?.toFixed(2)
                    : '0.00'
                }
                noBal={!portfolioStats?.annualizedROI}
              />
            )}
          </Box>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={isDown1000 ? 'column' : 'row'}
          mt={'52px'}
          mb={'32px'}
          alignItems={'center'}
          gap={'24px'}
        >
          <Typography
            sx={{
              fontFamily: 'Hellix',
              fontSize: isDown1000 ? '32px' : '48px',
              fontWeight: 600,
              color: '#FFFFFF',
              margin: '0px',
              lineHeight: '57.6px',
              flex: 2,
              width: '100%',
            }}
          >
            {participationType === 0
              ? 'Participated Sales'
              : participationType === 1
              ? 'My Referrals'
              : participationType === 2
              ? 'Claim tokens'
              : 'Participated Sales'}
          </Typography>

          {/* {hasData && ( */}
          <ParticipationTypeTabs
            tab={participationType}
            handleTabChange={handleParticipationTypeChange}
          />
          {/* )} */}
        </Box>

        {participationType === 0 &&
          (mySales?.length ? (
            <MySalesTable mySales={mySales} />
          ) : (
            <>{!isDown1000 ? <NoDataTable /> : <NoDataBox />}</>
          ))}
        {participationType === 1 &&
          (sortedArray.length > 0 ? (
            <MyReferralsTable myReferrals={sortedArray} />
          ) : (
            <>{!isDown1000 ? <NoDataTable /> : <NoDataBox />}</>
          ))}
        {participationType === 2 &&
          (mySales?.filter(
            sale => sale.ido.status === IdoStatus.TGE && sale.isInvestor,
          )?.length > 0 ? (
            <ClaimTable
              claimSales={mySales.filter(
                sale => sale.ido.status === IdoStatus.TGE && sale.isInvestor,
              )}
            />
          ) : (
            <>{!isDown1000 ? <NoDataTable /> : <NoDataBox />}</>
          ))}
      </Box>
    </Box>
  )
}

export default RevampedPortfolio
