import 'metamask-csp-firefox'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/configureStore'
import Web3Provider from './components/Web3Provider'
import MuiTheme from './theme'
import { PersistGate } from 'redux-persist/integration/react'
import * as amplitude from '@amplitude/analytics-browser'

import './index.css'

// WalletConnect does not work with webpack 5 i.e. react@18+
// This is required to make it work
import { Buffer } from 'buffer'
import { ParticipationEntryPointProvider } from './Context'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as unknown as any).Buffer = (window as unknown as any).Buffer || Buffer

console.log('started')

// amplitude.init('73b7617d7e536d286e2c757693a70218')

// disable logs for PROD
if (window.location.href.includes('apeterminal.io')) {
  console.log = () => {}
}

// show maintenance page
if (false) {
  ReactDOM.render(
    <React.StrictMode>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          fontSize: '42px',
          fontWeight: 700,
          backgroundColor: '#000',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        We are currently under maintenance. Please check back later.
      </div>
    </React.StrictMode>,
    document.getElementById('root'),
  )
} else
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <MuiTheme>
            <ParticipationEntryPointProvider>
              <Web3Provider />
            </ParticipationEntryPointProvider>
          </MuiTheme>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
