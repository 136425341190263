import { Box, Button, Typography } from '@mui/material'

const GreenButton = ({
  mt = 0,
  text,
  Icon,
  mb = 0,
  h,
  w,
  fs,
  fw,
  lh,
  br,
  onClick,
  sx,
  disabled = false,
  IconStart,
  fm,
  outlined,
  mr,
  p,
  border,
  id,
}: {
  mt?: number
  mb?: number
  text?: string
  Icon?: any
  p?: string
  h?: string
  w?: string
  fs?: number | string
  fw?: number
  lh?: string
  br?: string
  sx?: any
  onClick?: () => void
  disabled?: boolean
  IconStart?: any
  fm?: string
  outlined?: boolean
  mr?: number
  border?: any
  id?: string
}) => {
  return (
    <Button
      id={id}
      sx={{
        mt: mt,
        mb: mb,
        mr: mr,
        padding: p ? p : 'auto',
        height: h,
        width: w ? w : '100%',
        borderRadius: br,
        backgroundColor: outlined ? 'transparent' : '#63EA71',
        fontFamily: fm ? fm : 'Hellix',
        border: border ? border : outlined ? '1px solid #08493C' : 'none',
        gap: '10px',
        transition: 'all 0.2s ease',
        '&:disabled': {
          background: '#333333',
          border: 'none',
          cursor: 'not-allowed',
          pointerEvents: 'all !important',
        },
        '&:hover': {
          background: outlined
            ? 'transparent'
            : disabled
            ? '#333333'
            : '#27B079',
          border: outlined ? '1px solid #27B079' : 'none',

          // cursor: 'not-allowed',
          pointerEvents: 'all !important',
        },
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      {IconStart && <IconStart />}
      {text && (
        <Typography
          fontSize={fs ? fs : 17}
          fontWeight={fw ? fw : 500}
          lineHeight={lh && lh}
          color={outlined ? '#63EA71' : '#000'}
          textTransform={'capitalize'}
          sx={{
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
          fontFamily={fm ? fm : 'Hellix'}
        >
          {text}
        </Typography>
      )}

      {Icon && <Icon />}
    </Button>
  )
}

export default GreenButton
