import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  Tooltip,
} from '@mui/material'
import { ClipboardIcon, LinkIcon, TableProjectLogo } from '../../assets'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import { setSnackbar } from '../../redux/slices/themeSlice'
import {
  FixedBodyTableCell,
  FixedHeadTableCell,
  StyledBodyTableCell,
  StyledHeadTableCell,
} from './MySalesTable'
import {
  multiplierValue,
  replaceUnderscoresWithSpaces,
  shortenUrl,
  trimSpace,
} from '../../utils'
import { useAppSelector } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import { useAnalytics } from '../../AmplitudeContext'

const myReferrals = [
  {
    projectLogo: TableProjectLogo,
    projectName: 'AIT Protocol',
    projectSymbol: 'AIT',
    usedCount: 24,
    multiplier: 1.5,
    status: 'active',
    referralLink: 'S0x91abb7a8902c7538019756782672ed4572e70982',
  },
  {
    projectLogo: TableProjectLogo,
    projectName: 'Project 2',
    projectSymbol: 'P2',
    usedCount: 24,
    multiplier: 1.5,
    status: 'active',
    referralLink: 'S0x91abb7a8902c7538019756782672ed4572e70982',
  },
  {
    projectLogo: TableProjectLogo,
    projectName: 'Project 3',
    projectSymbol: 'P3',
    usedCount: 24,
    multiplier: 1.5,
    status: 'active',
    referralLink: 'S0x91abb7a8902c7538019756782672ed4572e70982',
  },
  {
    projectLogo: TableProjectLogo,
    projectName: 'Project 4',
    projectSymbol: 'P4',
    usedCount: 24,
    multiplier: 1.5,
    status: 'inactive',
    referralLink: 'S0x91abb7a8902c7538019756782672ed4572e70982',
  },
  {
    projectLogo: TableProjectLogo,
    projectName: 'Project 5',
    projectSymbol: 'P5',
    usedCount: 24,
    multiplier: 1.5,
    status: 'inactive',
    referralLink: 'S0x91abb7a8902c7538019756782672ed4572e70982',
  },
  {
    projectLogo: TableProjectLogo,
    projectName: 'Project 6',
    projectSymbol: 'P6',
    usedCount: 24,
    multiplier: 1.5,
    status: 'inactive',
    referralLink: 'S0x91abb7a8902c7538019756782672ed4572e70982',
  },
  {
    projectLogo: TableProjectLogo,
    projectName: 'Project 7',
    projectSymbol: 'P7',
    usedCount: 24,
    multiplier: 1.5,
    status: 'inactive',
    referralLink: 'S0x91abb7a8902c7538019756782672ed4572e70982',
  },
  {
    projectLogo: TableProjectLogo,
    projectName: 'Project 8',
    projectSymbol: 'P8',
    usedCount: 24,
    multiplier: 1.5,
    status: 'inactive',
    referralLink: 'S0x91abb7a8902c7538019756782672ed4572e70982',
  },
]

const getStatusStyle = (status: string) => {
  switch (status) {
    case 'Participation':
      return {
        backgroundColor: 'rgba(99, 234, 113, 0.1)',
        color: '#63EA71',
      }
    default:
      return {
        backgroundColor: 'rgba(255, 119, 34, 0.1)',
        color: '#FF7722',
      }
  }
}

const truncateString = (longString: string, maxLength: number = 21): string => {
  return (
    longString.substring(0, maxLength) +
    (longString.length > maxLength ? '...' : '')
  )
}

const MyReferralsTable = ({ myReferrals }: any) => {
  const isDown800 = useMediaQuery('(max-width:800px)')
  const [showCount, setShowCount] = useState(5)
  const dispatch = useDispatch()
  const { trackEvent } = useAnalytics();

  const handleReferralLinkCopy = (projectName: string, referralCode: string) => {
    trackEvent('ReferralLinkCopy', { project_name: projectName, referral_code: referralCode });
  };

  const navigate = useNavigate()
  const handleShowMore = () => {
    if (showCount < myReferrals.length) {
      setShowCount(showCount + 5)
    } else {
      setShowCount(5)
    }
  }

  const displayedMyReferrals = myReferrals.slice(0, showCount)

  const baseURL = window.location.origin
  const code = useAppSelector(state => state.user.refferCode)

  return (
    <Box width={'100%'} mb={'60px'}>
      <Box
        width={'100%'}
        maxWidth={'1200px'}
        display={'flex'}
        flexDirection={'column'}
        paddingLeft={'0px'}
        paddingTop={'0px'}
      >
        <TableContainer
          component={Paper}
          sx={{
            height: '100%',
            maxHeight: '414px',
            border: '1px solid #1D1F21',
            boxShadow: 'none',
            backgroundImage: 'none',
            flexShrink: 1,
            width: '100%',
            borderRadius: '16px',
            '&::-webkit-scrollbar': {
              display: 'flex',
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#313335',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#0F1113',
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              maxHeight: '408px',
              height: '100%',
              flexShrink: 0,
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  textWrap: 'nowrap',
                }}
              >
                {isDown800 ? (
                  <FixedHeadTableCell>Projects</FixedHeadTableCell>
                ) : (
                  <StyledHeadTableCell>Projects</StyledHeadTableCell>
                )}
                <StyledHeadTableCell>People used my link</StyledHeadTableCell>
                <StyledHeadTableCell>Multiplier</StyledHeadTableCell>
                <StyledHeadTableCell>Status</StyledHeadTableCell>
                <StyledHeadTableCell>My Referral links</StyledHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflowY: 'hidden',
                overflowX: 'auto',
                backgroundColor: '#0F1113',
              }}
            >
              {displayedMyReferrals.map((ido: any, index: any) => {
                let url =
                  baseURL +
                  '/project/' +
                  trimSpace(ido.project.name) +
                  '?code=' +
                  code
                return (
                  <TableRow
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#000000',
                      },
                    }}
                    onClick={() => navigate(`/project/${ido.project.name}`)}
                  >
                    {isDown800 ? (
                      <FixedBodyTableCell>
                        <img
                          src={ido.project?.logoUrl}
                          alt={ido.project.name}
                          style={{ width: 40, height: 40, borderRadius: 10 }}
                        />
                      </FixedBodyTableCell>
                    ) : (
                      <StyledBodyTableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            minWidth: '220px',
                          }}
                        >
                          <img
                            src={ido.project?.logoUrl}
                            alt={ido.project.name}
                            style={{ width: 40, height: 40, borderRadius: 10 }}
                          />
                          <Box ml={'12px'}>
                            <Typography
                              sx={{
                                color: '#FFFFFF',
                                fontSize: '15px',
                                fontWeight: 400,
                              }}
                            >
                              {replaceUnderscoresWithSpaces(ido.project.name)}
                            </Typography>
                            {/* <Typography
                              sx={{
                                color: '#7E8180',
                                fontSize: '12px',
                                fontWeight: 400,
                              }}
                            >
                              {'ido.project.symbol'}
                            </Typography> */}
                          </Box>
                        </Box>
                      </StyledBodyTableCell>
                    )}
                    <StyledBodyTableCell>
                      {ido.referralCount}
                    </StyledBodyTableCell>

                    <StyledBodyTableCell>
                      {multiplierValue(ido.multiplier)}
                    </StyledBodyTableCell>

                    <StyledBodyTableCell>
                      <Box
                        sx={{
                          height: '28px',
                          width: '88px',
                          borderRadius: '80px',
                          display: 'flex',
                          justifyContent: 'center',
                          textAlign: 'center',
                          alignItems: 'center',
                          fontSize: '12px',
                          fontWeight: 500,
                          fontFamily: 'Inter',

                          ...getStatusStyle(ido.ido.status),
                        }}
                      >
                        {ido.ido.status.charAt(0).toUpperCase() +
                          ido.ido.status.slice(1)}
                      </Box>
                    </StyledBodyTableCell>
                    <Tooltip title={url} placement="top-end">
                      <StyledBodyTableCell
                        sx={{
                          lineHeight: '20px',
                        }}
                      >
                        <img
                          src={LinkIcon}
                          style={{
                            marginRight: '8px',
                            height: '16px',
                            position: 'relative',
                            top: '2px',
                          }}
                          onClick={e => {
                            e.stopPropagation()
                          }}
                          alt="link"
                        />

                        {shortenUrl(url)}

                        <CopyToClipboard
                          text={url}
                          onCopy={() => {
                            dispatch(
                              setSnackbar({
                                open: true,
                                severity: 'success',
                                message: 'Copied',
                              }),
                            )
                          }}
                        >
                          <img
                            src={ClipboardIcon}
                            style={{
                              marginLeft: '8px',
                              height: '16px',
                              position: 'relative',
                              top: '2px',
                            }}
                            onClick={e => {
                              e.stopPropagation()
                              handleReferralLinkCopy(ido.project.name, code)
                            }}
                            alt="clipboard"
                          />
                        </CopyToClipboard>
                      </StyledBodyTableCell>
                    </Tooltip>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={2} textAlign="center">
        <OutlinedButton
          onClick={handleShowMore}
          sx={{ height: '48px', width: '100%' }}
        >
          {showCount < myReferrals.length ? 'Show More' : 'Show Less'}
        </OutlinedButton>
      </Box>
    </Box>
  )
}

export default MyReferralsTable
